import React, { Component } from 'react';

import Layout from '../components/layouts';
// import { Link } from '../components/styledComponents';
import Toggler from '../components/styledComponents/toggler';
import { Inner, Info } from '../components/styledComponents/tabsStyled';
import {
  LinkStyled,
  StyledContainer,
  Title,
  Page,
  PageTitle,
  TwoCols,
  ThreeCols,
  Text,
  Buttons,
  ButtonBlock,
  ButtonBlockTitle,
  ButtonBlockInfo,
  Button,
  Contacts,
  TogglersList,
  BlockTitle,
} from '../components/pages/career/career.styled';
import MapBlock from '../components/map/map';

class Career extends Component {
  // document.body.style.background = '#fff';

  render() {
    return (
      <Layout>
        <StyledContainer>
          <Title>Карьера</Title>

          <Page>
            <PageTitle className="center">Стань частью команды Курсус и получи уникальный опыт!</PageTitle>
            <TwoCols>
              <div className="pad-right">
                <Text>
                  Мы являемся продуктовой и проектной IT-компанией, работаем с 2008 года и стали признанными экспертами
                  рынка интеллектуальных транспортных систем.
                </Text>
              </div>
              <div className="with-arrow">
                <Text>
                  <b>Аккредитованная IT компания</b>
                  <br />
                  Программное обеспечение собственной разработки, зарегистрированное в реестре российского ПО
                </Text>
              </div>
            </TwoCols>
            <Buttons>
              <ButtonBlock>
                <ButtonBlockTitle>Выбирай актуальные вакансии</ButtonBlockTitle>
                <ButtonBlockInfo>
                  <Button
                    href="https://spb.hh.ru/employer/vacancies?hhtmFrom=vacancy&hhtmFromLabel=header"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    Вакансии
                  </Button>
                </ButtonBlockInfo>
              </ButtonBlock>
              <ButtonBlock>
                <ButtonBlockTitle>Развивайся вместе с нами</ButtonBlockTitle>
                <ButtonBlockInfo>
                  <Toggler
                    title="Стажировки, практика"
                    info={
                      <p>
                        Рады приветсвовать в команде Курсус студентов, практикантов, выпускников и молодых специалистов!
                        <br />
                        <br />
                        Профессионалы Курсус делятся уникальным опытом в формате наставничества и партнерства. Выбирай в
                        каких направлениях хочешь развиваться и напиши нам{' '}
                        <a href="mailto:office@cursusts.com">office@cursusts.com</a>.<br />
                        <br />
                        Официальное оформление с первого дня погружения.
                      </p>
                    }
                  />
                </ButtonBlockInfo>
              </ButtonBlock>
            </Buttons>
            <Contacts>
              <div>
                <p>позвони нам</p>
                <img alt="Телефон" src="/static/images/phone.png" />
                <a href="tel:+78123093645">7-812-309-36-45</a>
              </div>
              <div>
                <p>напиши нам</p>
                <img alt="email" src="/static/images/mail.png" />
                <a href="mailto:office@cursusts.com">office@cursusts.com</a>
              </div>
            </Contacts>

            {/* <PageTitle className="small">
              Комфортный офис в центре города в 5 минутах ходьбы <br />
              от станции метро &quot;Чернышевская&quot;
            </PageTitle> */}
            <TwoCols>
              <div>
                <Contacts className="margin0">
                  <div>
                    <p className="two-lines">
                      Комфортный офис в центре города в 5 минутах от станции метро &quot;Чернышевская&quot;
                    </p>
                    <img alt="Телефон" src="/static/images/location.png" />
                    <div>
                      Санкт-Петербург
                      <br />
                      ул. Радищева, д. 39, литера Д, офис 312
                    </div>
                  </div>
                </Contacts>
                <MapBlock fullWidth={false} height={300} />
              </div>
              <div></div>
            </TwoCols>
          </Page>

          {/* directions */}
          <Page>
            <PageTitle>В каких направления работают в Курсус</PageTitle>
            <TogglersList>
              <Toggler
                hover
                title="Разработка ПО"
                info={
                  <p>
                    Backend-разработка
                    <br />
                    Стек технологий: Python(Django),Postgre SQL, RabbitMQ
                    <br />
                    <br />
                    Frontend-разработка
                    <br />
                    Стек технологий: Js (angular, react), HTML, CSS, JavaScript, SVG
                  </p>
                }
              />
              <Toggler
                hover
                title="Системное администрирование"
                info={<p>Офисное системное администрирование, DevOps, Операционная система Linux</p>}
              />
              <Toggler
                hover
                title="Эксплуатация инженерных систем"
                info={
                  <p>
                    Интеграция ПО со слаботочными системами <br />
                    Проектирование <br />
                    Инсталляции и настройки оборудования <br />
                    Техническая и эксплуатационная документация <br />
                    Программирование микроконтроллеров
                  </p>
                }
              />
              <Toggler
                hover
                title="Управление проектами"
                info={
                  <p>
                    Руководство техническими и IT-проектами <br />
                    Обеспечение бесперебойной работы систем объектов, сопровождение ПО <br />
                    Закупка оборудования, комплектующих
                  </p>
                }
              />
              <Toggler
                hover
                title="Финансы и юриспруденция, HR, документационное обеспечение"
                info={<p>Бухгалтерское, юридическое, документационное, кадровое сопровождение бизнеса</p>}
              />
              <Toggler
                hover
                title="Аналитика"
                info={
                  <p>
                    Системный аналитик &mdash; профессия на стыке разработки, аналитики, менеджмента
                    <br />
                    <br />
                    Аналитик данных &mdash; сбор, анализ и обработка больших массивов данных
                    <br />
                    <br />
                    Бизнес-аналитик &mdash; поиск стратегически важных решений
                  </p>
                }
              />
            </TogglersList>
          </Page>

          {/* why */}
          <Page>
            <PageTitle>Почему стоит работать в Курсус</PageTitle>
            <Info>
              <Inner>
                <h4>Команда дружных профессионалов </h4>
                <ul className="outer-list">
                  <li>
                    Легкая и комфортная корпоративная культура, где приветствуется открытость, инициатива и здоровый
                    юмор
                  </li>
                  <li>Каждый из нас &mdash; лидер в своей области, каждый из нас &mdash; профессионал</li>
                </ul>
                <h4>Работа с интересными проектами и уникальными продуктами</h4>
                <ul className="outer-list">
                  <li>
                    Разрабатываем программно-аппаратные комплексы для интеллектуальных транспортных систем, а это &mdash;
                    инновационные решения для транспортной инфраструктуры с целью обеспечения безопасности человеческой
                    жизни и формирования позитивного опыта поездок для пользователей
                  </li>
                </ul>
                <h4>Адекватное руководство и демократический стиль управления, в котором люди &mdash; главная ценность</h4>
                <ul className="outer-list">
                  <li>Возможность яркой профессиональной самореализации</li>
                  <li>Гибкий график и возможность удаленной работы</li>
                  <li>Забота о сотрудниках: ДМС, подарки ко Дню Рождения</li>
                  <li>Комфортный офис в центре города в 5 минутах от метро</li>
                  <li>Полное официальное оформление</li>
                  <li>Корпоративные мероприятия</li>
                  <li>Мотивационные программы</li>
                </ul>
              </Inner>
            </Info>
          </Page>

          {/* values */}
          <Page>
            <PageTitle className="small">
              <b>Наша миссия</b> &mdash; повышение эффективности <b>транспортной инфраструктуры</b> за счет внедрения{' '}
              <b>оптимальных средств автоматизации</b>.
            </PageTitle>
            <PageTitle>Наши ценности</PageTitle>
            <ThreeCols>
              <div>
                <BlockTitle>1. Результат (продукт)</BlockTitle>
                <Inner className="block">
                  <ul className="outer-list">
                    <li>
                      <b>Безопасность (надежность):</b> человеческая жизнь
                    </li>
                    <li>
                      <b>Позитивный опыт</b> (комфорт, время): время, затрачиваемое на поездку и получаемое удовольствие
                      (или отсутствие негативных переживаний)
                    </li>
                    <li>
                      <b>Эффективность:</b> снижение затрат на управление (эмоциональных, финансовых)
                    </li>
                  </ul>
                </Inner>
              </div>
              <div>
                <BlockTitle>2. Компания</BlockTitle>
                <Inner className="block">
                  <p>
                    Какие ценности мы должны исповедовать каждый день в своей текущей работе, чтобы наилучшим образом
                    реализовать миссию нашей компании?
                  </p>
                  <ul className="outer-list">
                    <li>
                      <b>Надежность систем</b>
                    </li>
                    <li>
                      <b>Эффективность систем</b> (повышение эффекта, снижение затрат), в первую очередь за счет
                      внедрения новых технологий
                    </li>
                    <li>
                      <b>Качество продукта</b> для формирования <b>позитивного опыта пользователей:</b> водителей и
                      операторов
                    </li>
                  </ul>
                </Inner>
              </div>
              <div>
                <BlockTitle>3. Команда</BlockTitle>
                <Inner className="block">
                  <p>
                    Принципы в организации нашей работы позволяют нам создать Команду, способную реализовать Миссию,
                    обеспечивают нам возможность привлекать лучших специалистов, делают нашу компанию лучшей для работы:
                  </p>
                  <ul className="outer-list">
                    <li>
                      <b>Открытость и прозрачность</b>
                    </li>
                    <li>
                      <b>Целеустремленность</b>
                    </li>
                    <li>
                      <b>Дисциплина</b>
                    </li>
                  </ul>
                </Inner>
              </div>
            </ThreeCols>
          </Page>
          <Page>
            <PageTitle className="center">
              <LinkStyled to="/contacts">Присоединяйся к команде дружных профессионалов!</LinkStyled>
            </PageTitle>
          </Page>
        </StyledContainer>
      </Layout>
    );
  }
}

export default Career;
