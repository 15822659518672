import styled from 'styled-components';

export const Link = styled.button`
  /* display: inline-block; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
  background-color: #fc5b0e;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  border: 0;
  transition: 0.3s all ease-out;
  &:hover {
    background-color: #dc3b0e;
  }
`;

export const TogglerContent = styled.div`
  margin: 15px 0 0;
  padding: 20px 30px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #c9cbce;
  p {
    font-size: 16px;
    line-height: 1.56;
    color: #000;
  }
  &.hover {
    position: absolute;
    min-width: 400px;
    bottom: 100%;
    z-index: 10;
    margin-bottom: 15px;
  }
`;

export const Place = styled.div`
  position: relative;
`;
