import styled from 'styled-components';
import { Link } from 'gatsby';

import { Container } from '../../styledComponents';

export const StyledContainer = styled(Container)`
  .response-window {
    margin: 20px auto;
    box-shadow: none;
  }
`;

export const Title = styled.h1`
  margin: 0 0 60px;
  @media (max-width: 991px) {
    margin-bottom: 25px;
  }
`;

export const Page = styled.div`
  margin: 0 0 90px;
  @media (max-width: 991px) {
    margin: 0 0 40px;
  }
`;

export const PageTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: normal;
  margin: 0 0 40px;
  &.small {
    font-size: 18px;
    margin: 10px 0 25px;
    font-weight: normal;
  }
  b {
    color: #fc5b0e;
  }
  &.center{
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.56;
  color: #000;
`;

export const TwoCols = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px 50px;
  & > div {
    width: 50%;
    padding: 0 15px;
    &.pad-right {
      padding-right: 45px;
    }
    &.with-arrow {
      position: relative;
      padding-left: 55px;
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        background-image: url('/static/images/benefits-icon.png');
        width: 30px;
        height: 30px;
        margin: -15px 0 0;
      }
    }
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin: 0 0 50px;
    & > div {
      width: 100%;
      margin: 0 0 15px;
      padding: 0;
    }
  }
`;

export const Buttons = styled.div`
  margin: 0 0 90px;
`;

export const ButtonBlock = styled.div`
  display: flex;
  /* align-items: center; */
  margin: 0 0 15px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const ButtonBlockTitle = styled.div`
  position: relative;
  font-size: 18px;
  color: #000;
  padding-right: 20px;
  width: 325px;
  height: 50px;
  line-height: 50px;
  margin: 0 50px 0 0;
  flex-shrink: 0;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: -25px;
    top: 50%;
    margin-top: -4px;
    width: 34px;
    height: 8px;
    background-image: url(/static/images/orange-arrow.png);
    background-repeat: no-repeat;
  }
  @media (max-width: 991px) {
    width: auto;
  }
`;

export const ButtonBlockInfo = styled.div``;

export const Button = styled.a`
  /* display: inline-block; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
  background-color: #fc5b0e;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  border: 0;
  transition: 0.3s all ease-out;
  &:hover {
    background-color: #dc3b0e;
  }
`;

export const Contacts = styled.div`
  margin: 0 0 70px;
  display: flex;
  align-items: center;
  color: #2d2d2d;
  font-size: 18px;
  &.margin0 {
    margin: 0 0 30px;
  }
  & > div {
    margin: 0 50px 0 0;
    position: relative;
    display: flex;
    align-items: center;
    img {
      margin-right: 15px;
      width: 23px;
    }
    p {
      color: #666;
      position: absolute;
      padding: 0;
      margin: 0;
      font-size: 0.8em;
      left: 37px;
      top: -20px;
      &.two-lines {
        top: -40px;
      }
    }
    a,
    div {
      font-weight: bold;
      color: #2d2d2d;
    }
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
    font-size: 16px;
    & > div {
      width: 100%;
      margin: 0 0 35px;
    }
    &.margin0 {
      margin: -50px 0 0;
    }
  }
`;

export const TogglersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin: 0 15px 15px 0;
  }
`;

export const BlockTitle = styled.div`
  margin: 0 0 25px;
  color: #fc5b0e;
  font-weight: bold;
`;

export const ThreeCols = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px 50px;
  & > div {
    width: 30%;
    padding: 0 15px;
  }
`;


export const LinkStyled = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  color: #fc5b0e;
  transition: 0.25s all ease-out;
  &.active,
  &:hover {
    color: #2d2d2d;
  }
`;