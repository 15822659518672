import React from 'react';

import { Place, Link, TogglerContent } from './toggler.styled';

export default class Toggler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  toggle = () => {
    if (this.props.hover) {
      return;
    }
    const { show } = this.state;
    this.setState({ show: !show });
  };

  handleMouseEnter = () => {
    this.setState({ show: true });
  };

  handleMouseLeave = () => {
    this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    const { title, info, hover } = this.props;
    const other = {};
    if (hover) {
      other.onMouseEnter = this.handleMouseEnter;
      other.onMouseLeave = this.handleMouseLeave;
    } else {
      other.onClick = this.toggle;
    }

    const cl = hover ? 'hover' : '';

    return (
      <Place>
        <Link {...other}>{title}</Link>
        {show && <TogglerContent className={cl}>{info}</TogglerContent>}
      </Place>
    );
  }
}
